.modal-backdrop {
    background: #f9fafb;
    opacity: 0.1 !important;
    filter: blur(34px);
}

.modal-dialog {
    margin-top: 100px;
}

.modal-content {
    background: #ffffff;
    box-shadow: 2px 2px 20px rgba(30, 30, 30, 0.1) !important;
    border-radius: 12px;
    border: none;
    padding: 24px;
}

.confirm-application {
    &:disabled {
        background-color: $gray-300 !important;
        border: none !important;
    }
}

.connect-wallet {
    &__header {
        font-size: $font-size-lg;
        font-weight: 600;
        margin-bottom: 24px;

        &__close {
            font-size: 30px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    &__connection {
        background: #f6f6f6;
        padding: 16px;
        margin-top: 16px;
        border-radius: 16px;
        font-size: $font-size-sm;
        font-weight: 600;
        transition: $default-transition;

        &:hover {
            cursor: pointer;
            background: #ebeaea;
        }

        &__logo {
            background: url('../assets/images/meta-mask.svg') no-repeat center;
            background-size: cover;
            width: 40px;
            height: 40px;
            margin-right: 16px;
        }
    }
}

.application-modal {
    border-radius: 12px;
    &--wide {
        .modal-dialog {
            width: 700px !important;
            margin-top: 70px !important;
            transform: none !important;
        }

        .text-gray {
            max-width: 85%;
        }
    }

    &__icon {
        background: #d1fadf;
        border: 8px solid #ecfdf3;
        border-radius: 50%;
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        color: #039855;
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 15px;
    }

    .modal-dialog {
        width: 400px;
    }
}

//WYSIWG
.rdw-editor-wrapper {
    border: 1px solid #d0d5dd !important;
    border-radius: 8px !important;
    overflow: hidden;
}

.rdw-editor-main,
.rdw-editor-toolbar {
    border: none;
}

.rdw-editor-toolbar {
    border-bottom: 1px solid #d0d5dd !important;
}

.rdw-editor-main {
    padding: 0 19px;
    min-height: 150px;
}
