.redirect-page {
    width: 100vw;
    height: 100vh;
    background: $primary;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__logo {
        position: absolute;
        left: 50%;
        top: 63px;
        transform: translateX(-50%);
    }

    .btn {
        width: 120px;
    }
}
