.account {
    margin: 0 auto;
    &__backto-login,
    &__resend-email {
        font-size: $font-size-sm;
        color: $gray-500;
        margin-top: 32px;
    }

    &__text {
        color: $gray-500;
        margin-bottom: 32px;
    }

    &__title {
        font-size: 30px;
        margin-bottom: 5px;
    }

    &__logo {
        width: 50px;
        margin-bottom: 24px;
    }

    &__back {
        background-color: transparent !important;
        border: none;
    }

    &__btn {
        background-color: $primary;
        border-radius: 40px;
        padding: 10px;
        color: white;
        width: 100%;
    }
}
