$primary: #5720cd;
$primary-25: #fbf9fe;
$primary-50: #f5f1fd;
$primary-100: #e0d4f8;
$primary-400: #a27feb;
$primary-500: #7a5af8;
$secondary: #341478;
$dark: #1e1e1e;
$light: #f5f1fd;
$gray-25: #fcfcfd;
$gray-50: #f9fafb;
$gray-100: #f2f4f7;
$gray-200: #e4e7ec;
$gray-300: #d0d5dd;
$gray-400: #98a2b3;
$gray-500: #667085;
$gray-700: #344054;
$error-25: #fffbfa;
$error-50: #fef3f2;
$error-300: #fda29b;
$error: #f04438;
$error-600: #d92d20;
$error-700: #b42318;
$success-25: #f6fef9;
$success-50: #ecfdf3;
$success-600: #039855;
$success-700: #027a48;
