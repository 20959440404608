.container__sdgs {
    opacity: 0;
    position: absolute;
    visibility: hidden;
    transition:
        visibility 0s,
        opacity 0.3s linear;
}

.container__sdgs--toggle {
    visibility: visible;
    position: relative;
    opacity: 1;
    transition:
        visibility 0s,
        opacity 0.2s ease-in;
}

.icon-toggle {
    transform: rotate(180deg);
    transition: transform 0.2s ease-in-out;
}

.project,
.financier {
    border: 0.5px solid $gray-200;
    box-shadow:
        0px 1px 3px rgba(16, 24, 40, 0.1),
        0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: $radius-lg;
    padding: 25px 25px 55px 25px;
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
    background-color: white;

    &__header {
        margin-bottom: 35px;

        .info-alert {
            color: $primary;
            order: 3;
            width: 100%;
            font-size: $font-size-sm;
            padding-left: 45px;
            padding-right: 20px;
            position: relative;
            box-shadow:
                0px 1px 3px rgba(16, 24, 40, 0.1),
                0px 1px 2px rgba(16, 24, 40, 0.06);
            margin-bottom: 28px;
            background-color: $primary-25;
            border: 1px solid $primary-400;
            font-size: 14px;

            &:after {
                content: '';
                display: block;
                position: absolute;
                left: 25px;
                width: 20px;
                height: 20px;
                top: 27px;
                transform: translate(-50%, -50%);
                background: url('../../assets/images/icons/info-primary.svg')
                    no-repeat center;
                background-size: cover;
            }
            svg {
                width: 20px;
                height: 20px;
                margin-left: 12px;
                &:hover {
                    cursor: pointer;
                }
            }
        }

        &__company {
            color: $gray-500;
            font-size: 14px;
            display: flex;
            align-items: center;
        }

        &__link {
            color: $gray-400;
            font-size: $font-size-xs;
            margin-bottom: 10px;
            margin-top: 10px;
            &--active {
                color: $gray-900;
            }
            &:hover {
                color: $gray-900;
                text-decoration: none;
            }
        }

        &__logo-container {
            padding: 4px;
            border-radius: 2px;
            border: 1px solid $gray-200;
            margin-right: 24px;
            max-height: 70px;
            width: 62px;
            height: 62px;

            &-logo {
                width: 62px;
                height: 62px;
                border-radius: $default-radius;
            }
        }

        &__logo {
            width: 62px;
            height: 62px;
            margin-right: 24px;
            border-radius: $default-radius;

            &-solenium {
                width: 62px;
                height: 62px;
                margin-right: 24px;
                border-radius: $default-radius;
            }
        }

        &__title {
            font-size: $font-size-xxl;
            font-weight: 600;
            line-height: 29px;
        }

        &__name {
            font-size: $font-size-xxl;
            font-weight: 500;
            line-height: 29px;
        }

        &__location {
            font-size: $font-size-sm;
            color: $gray-400;
        }

        &__vote {
            width: 110px;
        }
        &__vote-wide {
            margin-top: 50px;
            width: 447px;
        }
        .rrss-btn {
            color: $gray-500;
            padding: 3px 14px;
            border: 1px solid $gray-500;
            border-radius: 100px;
            background-color: transparent;
            font-size: $font-size-xs;
            -webkit-appearance: none;
            -webkit-border-radius: 100px;
            &:hover {
                background-color: $gray-50;
            }
        }
    }
    &__table {
        margin-top: 50px;
        &__row {
            border-bottom: 1px solid $gray-200;
            padding-bottom: 8px;

            &-name {
                font-size: 12px;
                color: $gray-600;
            }

            &-content {
                font-size: 14px;
                padding: 18px 0;
                border-bottom: 1px solid $gray-200;
                color: $gray-900;
                font-weight: 500;
            }

            &-content:last-child {
                border-bottom: none;
            }
        }
        svg {
            color: $gray-400;
        }
    }

    &__info-box {
        border-radius: $radius-lg;
        border: 1px solid #e9e9e9;
        margin-top: 24px;
        width: 100%;

        &-sm {
            width: 140px;
        }

        &__sdgs {
            color: $primary;
            margin-top: 40px;
            margin-bottom: 22px;
            font-size: 14px;

            svg {
                margin-left: 10px;
                width: 20px;
                height: 20px;
            }
            &-imgs {
                width: 50%;
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                p {
                    margin-bottom: 0;
                    margin-left: 6px;
                    font-size: 16px;
                    font-weight: 500;
                    // max-width: 50%;
                }
                img {
                    padding: 3px;
                    border: 1px solid $gray-200;
                    border-radius: 2px;
                    width: 56px;
                    height: 56px;
                }
            }
        }

        &-margin {
            margin-right: 30px;
            border: none;
        }

        &-margin:last-child {
            margin-right: 0;
            border: none;
        }

        &__container {
            padding: 20px 30px;
            &-sm {
                width: 115px;
            }

            &--bordered {
                border-top: 1px solid #e9e9e9;
            }
        }

        &__label {
            font-size: $font-size-xs;
            color: $gray-400;
            font-weight: 400 !important;
            margin-bottom: 8px;
        }

        &__label-md {
            font-size: 14px;
            color: $gray-400;
            font-weight: 400;
        }

        &__number {
            font-size: 36px;
            font-weight: 600;
        }

        &__number-sm {
            font-size: 24px;
            font-weight: 600;
        }

        &__currency-sm {
            font-size: 16px;
            font-weight: 400 !important;
        }

        &__currency {
            font-size: 18px;
            font-weight: 400;
        }

        &__bold-text {
            font-weight: 500;
        }

        &__text {
            margin-top: 2px;
            font-weight: 300;
            line-height: 24px;
        }

        &__row {
            border-bottom: 1px solid $gray-200;
            margin-left: 2px;

            .row-name {
                font-size: 14px;
                font-weight: 400;
                color: $gray-700;
                padding: 7px 0;
            }
        }
    }
}

.row-content {
    padding: 9px 2px;
    font-size: 14px;
}

.blured-shadow {
    box-shadow:
        0px 1px 3px rgba(16, 24, 40, 0.1),
        0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
}

.vertical-separator {
    color: $gray-300;
    margin: 0 12px;
}
