.project-list-page {
    // padding: 40px 100px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.project-page,
.financiers-page {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 900px;
    margin: 0 auto;
}

.pagination-container {
    margin: 18px 0 26px 0;
}

.pagination {
    &__text {
        margin: 0 10px;
        font-size: 14px;
        &-disabled {
            color: $gray-300;
        }
    }
    &__btn {
        background-color: transparent;
        border: none;
    }
}

.button-nav {
    border-bottom: 1px solid $gray-300;
    padding: 0;
    width: 100%;
    margin-left: 0;
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;

    &__container {
        margin-right: 2%;
    }

    .tooltip-help {
        margin-right: 15px;
    }

    button {
        border: none;
        background-color: transparent;
        padding: 14px 3px;
        color: $gray-400;
        font-size: 15px;
        text-align: left;

        &:hover {
            border-bottom: 2px solid $primary;
            transition: border-bottom 0.05s linear;
        }
        &:disabled {
            color: $gray-300;
            border-bottom: 0px solid $primary;
            cursor: not-allowed;
        }
    }

    span {
        margin-left: 8px;
    }
    // :not(span):not(img):hover{
    //   border-bottom: 2px solid $primary;
    //   transition: border-bottom .05s linear
    // }

    .active {
        color: black;
        border-bottom: 2px solid $primary;
        transition: border-bottom 0.2s;
    }
}

// .bs-tooltip-top {
//   opacity: 1 !important;
// }

.tooltip-inner {
    background-color: white !important;
    opacity: 1 !important;
    font-size: 12px;
    text-align: left;
    padding: 12px;
    max-width: 320px !important;
    border-radius: 8px;
    box-shadow:
        0px 12px 16px -4px rgba(16, 24, 40, 0.1),
        0px 12px 16px -4px rgba(16, 24, 40, 0.1);
}
.bs-tooltip-right > .arrow::before {
    border-right-color: white;
}

.bs-tooltip-top > .arrow::before {
    border-top-color: white;
}

.btn-tooltip-name {
    color: $gray-700;
    font-weight: 500;
    margin-bottom: 5px;
}
.btn-tooltip-description {
    color: $gray-500;
    margin-bottom: 0%;
}

.searchbar__container {
    width: 100%;
    max-width: 900px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 0;

    &__searchbar {
        margin-right: 25px;
        width: 384px;
        height: 54px;
        position: relative;
        &__icon {
            position: absolute;
            height: 54px;
            display: flex;
            align-items: center;
            padding: 15px 19px;
        }
        input {
            border: none;
            box-shadow: 1px 1px 3px 0 rgba(16, 24, 40, 0.05);
            padding-left: 48px;
            width: 384px;
            height: 54px;

            &::placeholder {
                color: $gray-300;
            }
        }
    }
    &__sort {
        label {
            color: $gray-400;
            font-size: 14px;
        }
        .css-1s2u09g-control,
        .css-1s2u09g-control--menu-is-open,
        .css-1s2u09g-control--is-focused,
        .css-1pahdxg-control {
            border: none;
            box-shadow: 1px 1px 3px rgba(16, 24, 40, 0.05) !important;
            width: 208px !important;
            height: 54px;
        }
    }
}
