$default-radius: 4px;
$radius-lg: 8px;
$font-family-base: Inter;
$font-size-xs: 12px;
$font-size-sm: 14px;
$font-size-md: 16px;
$font-size-lg: 18px;
$font-size-xl: 20px;
$font-size-xxl: 24px;
$footer-height: 300px;
$nav-height: 117px;
$btn-border-radius: 20px;
$btn-border-radius-sm: 16px;
$btn-border-radius-lg: 32px;
$default-transition: all 0.3s ease;
$side-width: 289px;
$form-side-width: 392px;
$space: 'Space Grotesk';
