.outcome-editor {
    display: grid;
    grid-template-columns: 100px 1fr auto auto;
    gap: 10px;
    margin: 10px 0;
}

.outcome-editor-item {
    display: grid;
    grid-template-columns: subgrid;
    grid-column: span 4;
}

.input {
    order: unset !important;
}

.question-filters {
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
}
