.side-nav--form {
    width: $form-side-width;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;

    &__link:hover {
        text-decoration: none;
    }

    &__item {
        max-width: 250px;
        margin-bottom: 32px;

        &--active {
            .side-nav--form__item__title {
                color: $primary;
            }

            .side-nav--form__item__subtitle {
                color: $primary;
            }
        }
        &--error {
            .side-nav--form__item__title {
                color: $error-600;
            }

            .side-nav--form__item__subtitle {
                color: $error;
            }
            .side-nav--form__item__review {
                color: $error-600;
                margin-top: 8px;
                font-size: $font-size-sm;
                font-weight: 500;
            }
        }

        &__indicator {
            width: 32px;
            height: 32px;
            background: $gray-100;
            position: relative;
            border-radius: 50%;
            flex-shrink: 0;
            margin-right: 16px;

            &:after {
                content: ' ';
                width: 10px;
                height: 10px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: white;
                border-radius: 50%;
            }

            &:before {
                content: ' ';
                width: 2px;
                height: calc(100% + 80px);
                background: black;
                position: absolute;
                top: 32px;
                left: 15px;
                background: $gray-200;
                border-radius: 2px;
            }

            &--last {
                &:before {
                    display: none;
                }
            }

            &--error {
                background: $error-50 !important;
                // border: 1px solid $error;
                box-shadow: 0px 0px 0px 2px $error-300 !important;
                &:before {
                    background: $error-300;
                }
                &:after {
                    content: ' ';
                    position: absolute;
                    width: 10px;
                    height: 10px;
                    // left: 55%;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background: $error-300;
                }
            }
            &--active {
                background: $primary-50;
                border: 2px solid $primary;
                box-shadow: 0px 0px 0px 4px #f4ebff;
                &:before {
                    background: $primary-50;
                }
                &:after {
                    content: ' ';
                    position: absolute;
                    width: 10px;
                    height: 10px;
                    // left: 55%;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background: $primary;
                }
            }

            &--completed {
                background: #f5f1fd;
                box-shadow: 0px 0px 0px 2px $primary;

                &:after {
                    content: ' ';
                    position: absolute;
                    width: 16px;
                    height: 13px;
                    left: 55%;
                    display: block;
                    background: url('../../assets/images/check.svg') no-repeat
                        center;
                    background-size: cover;
                }

                &:before {
                    background: $primary;
                }
            }
        }

        &__title {
            color: $gray-700;
            font-weight: 600;
            font-size: $font-size-lg;
            line-height: 28px;
        }

        &__subtitle {
            color: $gray-500;
            line-height: 24px;
            font-size: $font-size-sm;
        }
    }
}

.silta-form {
    width: 100%;
    // height: calc(100% - 46px - 32px);
    // height: auto
    background: #ffffff;
    max-width: 900px;
    box-shadow: 2px 2px 10px rgba(30, 30, 30, 0.1);
    border-radius: 8px;
    padding: 50px 70px;
    // max-width: 1250px;
    // margin: 0 auto;

    &__breadcrumbs {
        margin-bottom: 24px;
        font-size: 14px;
        font-weight: 500;
        max-width: 900px;
        // max-width: 1250px;
        // margin: 0 auto 24px auto;

        button {
            padding: 10px 36px;

            &:hover {
                * {
                    text-decoration: none !important;
                    color: white !important;
                }
            }
        }
    }

    // &.form-group {
    // width: 100%;
    // max-width: 395px !important;
    // margin-right: 30px;
    // margin-bottom: 50px;
    // }

    &__title {
        font-size: $font-size-xxl;
        font-weight: 600;
    }

    &__subtitle {
        color: $gray-400;
        max-width: 550px;
        line-height: 24px;
        margin-top: 5px;
    }
    &__subtitle-long {
        color: $gray-400;
        max-width: 670px;
        line-height: 24px;
        margin-top: 5px;
    }

    &__section {
        padding-bottom: 35px;
        border-bottom: 1px solid $gray-200;
        margin-bottom: 35px;

        h1 {
            font-size: 36px !important;
        }
    }

    &__skeleton {
        &__item {
            border-radius: 5px;
            height: 40px;
            margin-bottom: 5px;
            background: linear-gradient(
                90deg,
                #e4e7ec 0%,
                #f2f4f7 47.92%,
                #d0d5dd 100%
            );

            &--small {
                height: 20px;
                background: $gray-200;
            }
        }
    }

    .no_border {
        border-bottom: 0px !important;
        margin-bottom: 0 !important;
        padding-bottom: 0;
    }

    &__pagination {
        .silta-form__section {
            margin-bottom: 15px;
        }

        &__btn {
            &--disabled {
                pointer-events: none !important;
            }
            .btn-next {
                font-size: $font-size-sm;
                padding-left: 0;
                padding-right: 0;
                color: $primary !important;
                text-decoration: none;

                &:hover {
                    color: $secondary !important;
                }
            }

            .btn {
                font-size: $font-size-sm;
                padding-left: 0;
                padding-right: 0;
            }
        }
        &__btn-back {
            .btn-back {
                color: $gray-500 !important;
                font-size: $font-size-sm;
                padding-left: 0;
                padding-right: 0;
                text-decoration: none;
            }
            :hover {
                color: $gray-700 !important;
            }
        }
    }

    .confirm-application {
        line-height: 35px;
        margin-top: 10px;
    }

    .form-group {
        width: 100%;
        max-width: 314px;
        margin-right: 30px;
        margin-bottom: 50px;
        @media (min-width: 1400px) {
            max-width: 400px;
            margin-bottom: 60px;
        }
        @media (min-width: 1200px) {
            max-width: 350px;
            margin-bottom: 60px;
        }
    }
    @media (min-width: 1400px) {
        max-width: 1100px;
        padding: 60px 80px;

        &__breadcrumbs {
            max-width: 1100px;
        }
        &__subtitle {
            max-width: 630px !important;
        }
    }
}
